@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Avenirmedium";
    src: url(../fonts/Avenirmedium.otf);
  }
  @font-face {
    font-family: "Avenirregular";
    src: url(../fonts/Avenirregular.otf);
  }
  @font-face {
    font-family: "Avenirdemi";
    src: url(../fonts/Avenirdemi.otf);
  }
  @font-face {
    font-family: "Myriadregular";
    src: url(../fonts/Myriadregular.otf);
  }
  @font-face {
    font-family: "Sinhalasangam";
    src: url(../fonts/Sinhalasangam.ttc) format("ttc");
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebf7ed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ColorContainer {
  background: linear-gradient(to bottom, #ebf7ed 30%, #148040 10%);
}
